// TODO. Refactor, use fetch.

let submitInProgress = false;

const updateResponseMessage = (errors) => {
	var response = '';
	if (errors.length === 1) {
		response = "The " + errors[0] + " field is required.";
	} else if (errors.length === 2) {
		response = "The " + errors[0] + " and " + errors[1] + " fields are required.";
	} else {
		let fields = "";
		for (let x = 0; x < errors.length; x++) {
			fields += errors[x];
			if (x < errors.length - 1) {
				fields += ', ';
			}
			if (x === errors.length - 2) {
				fields += 'and ';
			}
		}
		response = "The " + fields + " fields are required.";
	}
	document.querySelector('.response-message').innerHTML = "<span class='error'>" + response + "</span>";
};

export const contactSubmit = (formNode, required) => {

	formNode.addEventListener('submit', function (e) {
		e.stopImmediatePropagation();
		e.preventDefault();

		if (document.getElementById('hp').value !== '') return;

		Array.prototype.forEach.call(formNode.querySelectorAll('.error'), function (error) {
			error.classList.remove('error');
		});
		formNode.querySelector('.response-message').innerHTML = '';

		if (submitInProgress) {
			return;
		}

		let errorsFound = new Array();
		required.forEach(function (item) {
			if (document.getElementById(item + '-input').value === '') {
				document.getElementById(item + '-input').classList.add('error');
				errorsFound.push(item);
			}
		});
		if (!submitInProgress && errorsFound.length === 0) {
			submitInProgress = true;

			let request = new XMLHttpRequest(),
				method = 'POST',
				url = formNode.getAttribute('action'),
				data = new FormData(formNode);

			request.onreadystatechange = function () {

				if (request.readyState === 4 && request.status === 200) {
					let response_obj = JSON.parse(request.responseText);
					const response = response_obj.ret_det;
					
					if (response.success) {
						let input = document.querySelector('[data-js="contact-form-submit"]');
						input.parentElement.removeChild(input);
						formNode.querySelector('.response-message').innerHTML = "<span class='success'>" + response.message + "</span>";
					} else {
						if (typeof response.errors !== 'undefined') {
							let errors = [];
							for (let x = 0; x < response.errors.length; x++) {
								errors.push(response.errors[x].field_name);
							}
							updateResponseMessage(errors);
						} else if (response.message) {
							formNode.querySelector('.response-message').innerHTML = "<span class='error'>" + response.message + "</span>";
						}
					}
					submitInProgress = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		} else if (errorsFound.length > 0) {
			updateResponseMessage(errorsFound);
		} else {
			formNode.querySelector('.response-message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
	});
};
