import Contact from "./services/Contact.js";
import Map from "./services/Map.js";
import Slider from "./services/Slider.js";

document.addEventListener(
	"DOMContentLoaded",
	() => {
		/* eslint-disable */
		const facilitySlider = new Slider('[data-slider="facility-images"]');
		const homeSlider = new Slider('[data-slider="home-images"]');
		const singleFacilityMap = new Map('[data-map="single-facility"]');
		const reviewsSlider = new Slider('[data-slider="reviews"]');
		const formContact = new Contact('[data-form="contact"]', [
			"name",
			"phone",
			"email",
			"subject",
			"message",
		]);

		// Single Facility - Filter Rates
		(() => {
			const facilityRatesWrapper = document.querySelector("[data-filter]");
			const facilityFilters = document.querySelector("[data-filters]");

			if (!facilityRatesWrapper || !facilityFilters) {
				return;
			}

			facilityFilters.addEventListener("change", () => {
				const formData = new FormData(facilityFilters);
				const filterName = formData.get("facility-filter");
				facilityRatesWrapper.setAttribute("data-filter", filterName);
			});
		})();
		//mobile menu
		(() => {
			function toggleState(elem, stateOne, stateTwo) {
				elem.dataset.state =
					elem.dataset.state === stateOne ? stateTwo : stateOne;
			}

			function setState(elem, state) {
				elem.dataset.state = state;
			}

			document
				.querySelector("#toggle_mobile_nav")
				.addEventListener("click", function() {
					toggleState(this, "closed", "open");
					toggleState(document.querySelector("#nav"), "closed", "open");
				});
			/* eslint-enable */
		})();
	},
	true
);
