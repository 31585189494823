import { tns } from "tiny-slider"; // https://github.com/ganlanyuan/tiny-slider

export default class Slider {
	constructor(sliderSelector) {
		this.sliderNode = document.querySelector(sliderSelector);
		this.sliderWrapperNode = null;
		this.sliderType = null;
		this.sliderConfig = null;
		this.init();
	}

	getSliderConfig(type) {
		if (!this.sliderWrapperNode || !type) {
			return;
		}

		const config = {
			container: this.sliderNode,
			speed: 500,
			nav: false,
			autoplayButtonOutput: false,
			items: 1,
		};

		/* eslint-disable */
		switch (type) {
			case "reviews": {
				return Object.assign(config, {
					prevButton:
						this.sliderWrapperNode.querySelector(".slider-arrow-left"),
					nextButton: this.sliderWrapperNode.querySelector(
						".slider-arrow-right"
					),
					center: true,
					loop: false,
					items: 1,

					responsive: {
						1281: {
							items: 2,
							center: false,
							nav: false,
							gutter: 100,
						},
					},
				});
			}
			case "facility-images": {
				return Object.assign(config, {
					controls: false,
					nav: true,
					navContainer: ".gallery-dots",
					loop: true,
					autoplay: true,
					center: true,
					autoplayTimeout: 7000,
				});
			}
			case "home-images": {
				return Object.assign(config, {
					controls: false,
					nav: true,
					navContainer: ".gallery-dots",
					loop: true,
					autoplay: true,
					center: true,
					autoplayTimeout: 7000,
				});
			}
			default: {
				return config;
			}
		}
	}
	/* eslint-enable */

	init() {
		if (!this.sliderNode) {
			return;
		}

		this.sliderWrapperNode = this.sliderNode.parentNode;

		if (!this.sliderWrapperNode) {
			return;
		}

		this.sliderType = this.sliderNode.getAttribute("data-slider");
		if (!this.sliderType) {
			console.error("sliderType not found");
			return;
		}

		this.sliderConfig = this.getSliderConfig(this.sliderType);
		if (!this.sliderConfig) {
			console.error("sliderConfig not found");
			return;
		}

		this.slider = tns(this.sliderConfig);
	}
}
