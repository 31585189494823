import {
	getMapsHelper
} from '../util/mapsHelper.js';

export default class Map {
	constructor(mapSelector, locationElementSelector = '[data-map-coords]') {
		this.mapNode = document.querySelector(mapSelector);
		this.locationElementSelector = locationElementSelector;
		this.init();
	}

	init() {
		if (!this.mapNode) {
			return;
		}
		this.sectionNode = this.mapNode.closest('section');

		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: this.mapNode,
					locationElementSelector: this.locationElementSelector,
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
								<a 
									class="pin-marker" 
									href="${el.getAttribute('link')}" 
									target="${el.getAttribute('target')}"
									data-pin="${el.getAttribute('data-pin')}"
								>
									<img 
										class="pin-marker-icon"
										src="/dist/images/icons/map_pin.svg" 
										alt="Visit facility"
									>
									<span class="pin-marker-index">
										${el.getAttribute('data-pin')}
									</span>
								</a>
            	`
						};
					},
				});

				/* eslint-disable */
				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (document.querySelectorAll('.location-item').length < 2) {
						theMap.setZoom(15);
					}
				});
				/* eslint-enable */
			});
	}
}
